@page {
    size: landscape;
    margin: 0.75in 0.75in 0.75in 0.75in; 
}

@media print {
    .topbar__button {
        display: none;
    }

    .topbar__logo {
        text-decoration: none !important;

        & .wordmark {
            display: block;
        }
    }

    .topbar__right {
        display: none;
    }

    .container, 
    .dashboard.container > .row, 
    .container__wrap,
    .page-title {
        background-color: #fff !important;
    }

    body, 
    .dashboard.container {
        width: 100% !important;
        min-width: 100% !important;
    }

    .iframe-container {
        width: 100% !important;
        min-width: 100% !important;

        & iframe {
            min-width: 100%;
            
        }

    }
}

/*
@page {
    size: landscape;
    margin: 0.5in 0.25in 0.25in 0.25in; 
}
@media print {
    .loading-wrapper, .page-info-section {
        display: none !important;
    }
    .topbar {
        background-color: transparent !important;
    }
    .topbar__logo {
        position: fixed;
        top: 0.125in !important;
        left: 0 !important;
        margin: 0 !important;
        background-color: transparent !important;
    }
    .row__dashboard-intro {
        position: fixed;
        top: 0.5in !important;
        width: 100% !important;
        z-index: 2;
    }
    .dashboard-intro {
        padding: 0 !important;
        background-color:transparent !important;
    }
    .iframe-container {
        width: 100% !important;
        min-width: 100% !important;
        padding: 0 !important;
    }
    .iframe-container .iframe-wrapper {
        display: block;
        position:fixed;
        top: auto !important;
        margin-top: -30px !important;
        width: 10.5in !important;
        height: 6.5in !important;
    }
    .iframe-container .iframe_web {
        display: block;
        min-width: 10.5in !important;
        height: 6.5in !important;
    }
}

*/