.files-container {
    flex-wrap: wrap;
}
.files-container .full-width {
    flex: 0 0 100%;
}
.files-folders-list {
    padding: 0;
    margin: 1em 0;
    border-top: 1px solid #e4e2e7;
    & li {
        list-style: none;
        padding: .5em 0;
        margin: 0;
        border-bottom: 1px solid #e4e2e7;
    }
}

.file-item {
    display:flex; 
    align-items: center;
}

.file-info {
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;

    & .file-info__name {
        line-height: 1.2;
        cursor: pointer;

        &:hover,:focus {
            color: $color-orange
        }
    }
    & .file-info__details {
        font-size: 0.875em;
        color: #757575;
    }
}

.folder-item {
    display: flex;
    align-items: center;
    
}

.folder-info {
    cursor: pointer;
    margin-left: 0.5em;

    &:hover,:focus {
        color: $color-orange;
    }
}

.folder-nav {
    display: flex;
}

.folder-nav-item:not(.folder-nav-item__current) {
    display: inline-flex;
    cursor: pointer;
    font-weight: bold;

    &:hover,:focus {
        color: $color-orange
    }
}

.folder-nav-item__current {
    display: inline-flex;
}

.folder-nav-separator {
    display: inline-flex;
    padding: 0 4px;
    color: $color-gray;
}