.leads-table-wrapper {
    position: relative;
    max-height: calc(100vh - 320px);
    overflow: auto;
    padding: 0 10px 45px 10px;

    & header {
        font-size: 1.125em;
        padding: 0.5em 0;
    }
}

.leads-summary-wrapper {
    width: 100%;
    margin: 0 0 15px;
    
    & > .card:first-child {
        min-height: 90px;
    }

    & .top-border {
        border-top: 5px solid $color-orange;
    }

    & .leads-summary-container {
        display: flex;
        justify-content: space-between;
    }

    & .leads-summary-header-groups {
        font-size: 1.125em
    }

    & .leads-summary-header-activity {
        font-size: 0.875em;
    }

    & .leads-header-name {
        display: flex;
        justify-content: space-between;
        font-size: 1.125em;
    }

    & .leads-header-details {
        padding-left: 2em;
        font-size: 0.875em;
        
        & .drill-donor-activity {
            display: inline-block;

            & + .drill-donor-activity:before {
                display: inline-block;
                content: '|';
                padding: 0 6px;
            }
        }
    }

    & .summary-activity {
        display: inline-block;

        & + .summary-activity:before {
            display: inline-block;
            content: '|';
            padding: 0 6px;
        }
    }

    & .leads-export-title {
        display: block;
        width: 100%;
        text-align:center;
    }

    & .back-btn {
        cursor:pointer;
        line-height: 0;
        margin-right: 0;
        color:rgba(33, 37, 41, 0.8);

        &:hover,
        :focus {
            color:rgba(33, 37, 41, 1)
        }
    }
}

.leads-summary-export {
    position: relative; 
    display: flex;
    align-items: center;

    & .leads-export-menu {
        position: absolute; 
        right: 0;
        top: calc(100% - 6px);
        min-width: 120px;
        z-index: 3;
        background-color: #fff;
        padding: 4px 0;
        border: 1px solid #f4f2f7;
        box-shadow: 1px 0 3px #ddd;
        transition: opacity 0.2s;
        font-size: 0.875em;

        & .leads-export-btn {
            position: relative;
            padding: 1em 1.5em;
            margin: 0;
            border: none;
            display: block;
            width: 100%;
            background-color:#fff;
            white-space: nowrap;
            text-align: left;

            &:hover,
            &:focus {
                background-color: #f4f2f7;
            }

            // & + .leads-export-btn:before {
            //     position: absolute;
            //     content: '';
            //     display: block;
            //     height: 1px;
            //     width: 100%;
            //     top:0;
            //     left:0;
            //     background-color: #d4d2d7;
            // }
        }

    }


    & .leads-export-toggle-btn {
        font-size: 0.875em;
        background-color:#f4f2f7;
        border: 1px solid #d4d2d7;
        padding: 2px 4px 3px;
        border-radius: 4px;
        transition: background-color 0.2s;
    
        &:hover,
        &:focus {
            background-color:#d4d2d7;
        }
    
        & svg {
            transform: scaleX(1.125)
        }
    }

    & .leads-whats-new-btn {
        font-size: 0.875em;
        background-color:#f4f2f7;
        border: 1px solid #d4d2d7;
        padding: 2px 4px 3px;
        border-radius: 4px;
        margin-right: 10px;
        transition: background-color 0.2s;
    
        &:hover,
        &:focus {
            background-color:#d4d2d7;
        }
    
        // & svg {
        //     transform: scaleX(1.125)
        // }
    }

    & .leads-edit-menu {
        position: absolute; 
        right: 0;
        top: calc(100% - 6px);
        min-width: 120px;
        z-index: 3;
        background-color: #fff;
        padding: 0.5em 1em;
        border: 1px solid #f4f2f7;
        box-shadow: 1px 0 3px #ddd;
        transition: opacity 0.2s;
        font-size: 0.875em;

        & ul {
            margin: 0;
        }
        & li {
            white-space: nowrap;
            padding: 4px 0;
        }
    }

    & .leads-edit-btn {
        font-size: 0.875em;
        background-color:#f4f2f7;
        border: 1px solid #d4d2d7;
        padding: 2px 4px 3px;
        margin-right: 1em;
        border-radius: 4px;
        transition: background-color 0.2s;
    
        &:hover,
        &:focus {
            background-color:#d4d2d7;
        }
    
        // & svg {
        //     transform: scaleX(1.125)
        // }
    }

    & .show-false {
        opacity: 0;
        z-index: -1;
        appearance: none;
        pointer-events: none;
    }
}


.leads-table {
    width: 100%; 
    background: #fff;
    border-collapse: collapse;

    &#leads-detail-table {
        margin-bottom: 0.75rem;
    }

    & thead {
        background-color: $color-orange;
        width: 100%;
        padding: 0 10px;
        position: sticky; 
        top: 0; 
        z-index: 2;
        

        &:before {
            position: absolute;
            content: '';
            display: block;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: -10px;
            right: -10px;
            background-color: $color-orange;
        }

        & th {
            color:#fff;
            padding: 0.25em;
            font-size: 0.875em;
            line-height: 1.25;
            height: 50px;
            vertical-align: middle;

            & .th-inner {
                display:flex;
                align-items:center;
            }

            &.cell-align-left {
                text-align: left;
                justify-content: flex-start;
            }

            &.cell-align-center {
                text-align: center;

                & .th-inner {
                    justify-content: center;
                }
            }
        }

        

        & .sort-arrows {
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            height: 16px;
            width: 16px;
            padding-right: 6px;
            padding-left: 0;
            cursor: pointer;

            &:before {
                content:'';
                display:inline-flex;
                width: 8px;
                height: 7px;
                background-size: cover;
                background-position: center;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 11 9.5'%3E%3Cpath fill='%23FFFFFF' d='M0,9.5L5.5,0L11,9.5H0z'/%3E%3C/svg%3E");
                opacity:1;
                transition:0.1s opacity;
            }
            
            &:after {
                content:'';
                display:inline-flex;
                width: 8px;
                height: 7px;
                background-size: cover;
                background-position: center;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 11 9.5'%3E%3Cpath fill='%23FFFFFF' d='M0,9.5L5.5,0L11,9.5H0z'/%3E%3C/svg%3E");
                transform: rotate(180deg);
                opacity:1;
                transition:0.1s opacity;
            }

            &.desc:before {
                opacity:0;
            }

            &.asc:after {
                opacity:0;
            }
        }
        
        
    }

    & tbody {
        width: 100%;
    }
    & tr {
        border-bottom: 1px solid #ddd;

        & td {
            font-size: 0.875em;
            line-height: 1.25;
            padding: 0.25em;

            &.cell-align-left {
                text-align: left;
            }

            &.cell-align-center {
                text-align: center;
            }

            &.fullname-col span {
                display: block;
                width: 100%;
                max-width: 180px;
            }

            &.emailaddress-col span {
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 220px;
            }

            &.lead-group-cool {
                background-color: #90c4e4;
            }
        
            &.lead-group-warm {
                background-color: #fbad56;
            }
        
            &.lead-group-hot {
                background-color: #fd9a93;
            }

            &.intention-yes {
                background-color: #7bdf88;
            }
        }

        &:hover {
            

            & td {
                background-color: #e5e8eb;

                &.drill-cell {
                    cursor: pointer;
                }

                &.lead-group-cool {
                    background-color: #83b3d1;
                }
            
                &.lead-group-warm {
                    background-color: #eaa14d;
                }
            
                &.lead-group-hot {
                    background-color: #ed8d86;
                }

                &.intention-yes {
                    background-color: #72cf7e;
                }
            }
        }
    }

    
}


.response-table th .sort-arrows {cursor:pointer}

#leads-table-nav {
    position: relative;
    display: block;
    z-index: 2;
    border-top: 1px solid #ccc;
    padding: 4px 8px;
    background: #fff;
    bottom: 1.8rem;
    margin-bottom: -1.8rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    

    &:before {
        position: absolute;
        content:'';
        display: block;
        top: -4rem;
        height: 3.9rem;
        left: 0;
        width: 100%;
        background:
        linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    }

    & .footer-nav-descriptor {
        font-weight: 600;
        margin-right: 2px;
    }

    & .footer-nav-link {
        padding: 2px 3px;
        margin: 0 2px;

        &:not(.current-page):hover,:not(.current-page):focus {
            color: $color-orange;
            font-weight: bold;
            cursor: pointer;
        }

        &.current-page {
            font-weight:bold;
            cursor: default;
        }
    }
}