.popover {
    font-size: 1.33rem;
    max-width: 80vw;
    padding: 1rem 1.5rem;
    color: #fff;
    background: $color-gray;


    & .popover-header {
        font-size: 1.33rem;
        font-weight: 600;
        color: #fff;
        background: $color-gray;
        border: none;
        padding: 0 0 1rem;
        margin: 0;
    }

    & .popover-body {
        padding: 0; 
        margin: 0;

        & * {
            color:#fff;
        }

        & strong {
            font-weight: 600;
        }
    }

    & .arrow {

        &:after {
            border-bottom-color: $color-gray !important;
        }
    }

    & a {
        text-decoration: underline;
    }
    
    @media (min-width:768px) {
        max-width: 50vw;
    }

    @media (min-width:1200px) {
        max-width: 600px;
    }
}

.hover-tooltip__inner {
    padding: 2px 8px;
    font-size: 1.2rem;
    background-color: #222427;
}

.hover-tooltip__arrow::before {
    border-top-color: #222427 !important;
}
.tooltip-title {
    position: relative;
    display: inline-flex;

    /* Tooltip text */
    & .tooltip-text {
        visibility: hidden;
        width: 100%;
        max-width: 300px;
        font-size: 1.2rem;
        background-color: #222427;
        color: #fff;
        padding: 2px 8px;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        bottom: calc(100% + 5px);
        left: 0;
        display: inline-flex;

        & .tooltip-arrow {
            position: absolute;
            display: block;
            width: 0.8rem;
            height: 0.4rem;
            bottom: -0.4rem;
            left: 20px;

            &:before {
                position: absolute;
                content: "";
                border-color: transparent;
                border-style: solid;
                top: 0;
                border-width: 0.4rem 0.4rem 0;
                border-top-color: #222427;
            }
        }
    }

    &.tooltip-btn {

        & .tooltip-text {
            width: auto;
            line-height: 1.25;
            left: 50%;
            transform: translateX(-50%);

            & .tooltip-arrow {
                left: 50%;
                transform:translateX(calc(-50% - 0.4rem))
            }
        }
    }
    & .leads-whats-new-alert {
        visibility: visible;
        display:block;
        width: 220px;
        font-size: 1.5rem;
        line-height: 1.33;
        background-color: $color-blue;
        color: #fff;
        padding: 8px 12px;
        border-radius: 5px;
        position: absolute;
        z-index: 10;
        top: calc(100% + 12px);
        right: 0;
        font-weight: 600;
        display: inline-flex;
        border: 2px solid #fff;
        box-shadow: 0 2px 4px #555;

        & .tooltip-arrow {
            position: absolute;
            display: block;
            width: 1.2rem;
            height: 0.6rem;
            top: -0.6rem;
            right: 14px;

            &:before {
                position: absolute;
                content: "";
                border-color: transparent;
                border-style: solid;
                top: 0;
                border-width: 0 0.6rem 0.6rem;
                border-bottom-color: $color-blue;
            }
        }
    }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover,
  &:focus {
    & .tooltip-text {
        visibility: visible;
    }
  }

}
