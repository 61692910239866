.login__logo {
  display:flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 150px;
  height: 50px;
  padding-left:0px;
  margin: 0 auto 2rem auto;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: contain;

  @include themify($themes) {
    background-image: themed('logoImg');
  }
}
