.filter-cards {
    position: relative;
    z-index: 3;
}

.lead-name-selector {
    & .combobox__input-wrapper {
        width: 100%;

        & input {
            display: block;
            width: 100%;
        }
    }

    & input {
        border: 1px solid #ddd;
        font-size: 0.875em;
        line-height: 1.25;
        padding: 8px 8px;

        &::placeholder {
            font-size: inherit;
            font-style: normal;
        }
    }
}

.checkbox-selector {
    display: flex;
    flex-direction: column;

    & fieldset {
        padding: 0;
        margin: 0;
        border: none;
    }

    & legend {
        font-size: 1.6rem;
    }

    & .checkbox-selector-toggle-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content:space-between;
        border: 1px solid #ddd;
    }

    & .checkbox-selector-toggle {
        display: block;
        width: 100%;
        border: none;
        box-shadow: none;
        background:#fff;
        color:#666;
        font-size: 0.875em;
        line-height: 1.25;
        padding: 8px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .checkbox-dropdown {
        padding: 0.5em 0.25em;
        position: absolute;
        padding: 0.5em 25px;
        background: #fff;
        width: 100%;
        left: 0;
        top: calc(100% - 6px);
        z-index: 2;
        border-radius: 4px;
        box-shadow: 0 5px 4px #ddd;

        & ul {
            margin: 0 0 0.5em;
            padding: 0;

            & li {
                display: block;

                & label {
                    cursor: pointer
                }

                & input[type=checkbox] {
                    margin-right: 8px;
                    accent-color: $color-orange;
                    cursor: pointer;
                }
            }
        }
    }

    & .checkbox-selector-toggle-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: none;
        width: 4.4rem;
        flex: 0 0 4.4rem;
        padding: 1px 6px;
        cursor: pointer;
    }

    // .checkbox-dropdown {
    //     position: absolute;
    //     padding: 0.5em 25px;
    //     background: #fff;
    //     width: 100%;
    //     left: 0;
    //     top: calc(100% - 5px);
    //     z-index: 2;
    //     border-radius: 4px;
    //     box-shadow: 0 3px 3px #ccc;
    // }

    & .dropdown-submit-btn-wrapper {
        display: block;
        text-align: right;
    }
    & .dropdown-submit-btn {
        border: none;
        font-size: 0.875em;
        line-height: 1.25;
        padding: 0.5em 1em;

        &.cancel-btn {
            background-color:#fff;
            border: 1px solid #ddd;
        }

        &.apply-btn {
            background-color: $color-orange;
            border: 1px solid $color-orange;
            color: #fff;
        }

        & + .dropdown-submit-btn {
            margin-left: 1em;
        }
    }
}

.radio-selector {
    display: flex;
    flex-wrap: wrap;

    & fieldset {
        padding: 0;
        margin: 0;
        border: none;
    }

    & legend {
        font-size: 1.6rem;
    }

    .radio-selector-item {
        display: inline-flex;
        align-items: center;
        padding-right: 1em;
        margin-bottom: 0.25em;
        accent-color: $color-orange;

        & input[type=radio] + span {
            display: inline-flex;
            margin: 0 0 0 0.25em;
        }

        & input[type=radio] {
            &:hover {
                cursor: pointer;
            }
        }

        & label {
            margin: 0;

            &:hover {
                cursor: pointer;
            }
        }

    }
    
}

.react-datepicker {
    font-family: inherit;
    font-size: 1.2rem;

    & .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header  {
        font-size:1.2rem;
    }

}

.date-picker-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    font-size: 1.4rem;
    line-height: 1.25;
    padding: 0;
    
    // &:focus-within {
        
    // }

    & input {
        width: calc(100% - 40px);
        border: none;
        padding: 10px 8px;
        margin: 0;

        &:focus {
            outline: none;
            border: none;
        }
    }

    & > button { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        border: none; 
        background: none;
    }
}

.activity-selector, .visit-source-selector {
    & .select-dropdown {
        &.invisible {
            opacity: 0;
        }
        &.visible {
            opacity: 1;
        }
        &.sidemenu {
            left: 100%;
            bottom: 0;
            top: auto;
            border-radius: 4px 4px 4px 0;
            box-shadow: 3px 1px 3px rgba(0,0,0,0.3);
            width: calc(100% + 30px);
            border-left: 1px solid #ddd;

            & ul:has(li:nth-child(14)) {
                max-height: 360px;
                overflow: scroll;
                padding-bottom:40px;
            }
            
            & ul:has(li:nth-child(14)) + .dropdown-submit-btn-wrapper:before {
                content: '';
                display: block;
                height: 30px;
                background: linear-gradient(0deg, #fff, transparent);
                width: 100%;
                left: 0;
                position: relative;
                margin-top: -30px;
            }
        }
    }
}

.date-range-selector {

    & fieldset {
        padding: 0;
        margin: 0;
        border: none;
    }

    & legend {
        font-size: 1.6rem;
        width: auto;
    }

    & .legend-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .calendar-toggle {
        font-size:0.875em;
        padding: 0.25em;
        border-radius: 3px;
        background-color:#f2f4f7;
        border: none;
    }
}

.date-presets {
    display: flex;
    flex-wrap: wrap;
}

.date-preset-button {
    border: none;
    border-radius: 3px;
    background-color: #f2f4f7;
    font-size: 0.875em;
    line-height: 1.25;
    padding: 4px 6px;
    margin: 4px 10px 4px 0;

    &:hover,
    &:focus {
        background-color: rgb(224, 226, 231)
    }
}

.date-range-item {
    position: relative;
}

.litepicker-wrapper {
    position: absolute;
    top: 100%;
    z-index: 2;
    background-color: #fff;

    margin-left: -20px;
    padding:10px 20px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px #ccc;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}
  
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}