p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 0;

  @include themify($themes) {
    color: themed('colorText');
  }
}

p {
  margin: 10px 0 0;

  &:first-child {
    margin: 0;
  }
}

h1 {
  font-size: 3.25rem;
  line-height: 1.33;

  &.subhead {
    font-size: 3.0rem;
    color: $color-additional;
    line-height: 1.2;
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.2;

  &.subhead {
    font-size: 2.4rem;
    color: $color-additional;
    line-height: 1.5;
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 1.33;

  &.subhead {
    font-size: 1.8rem;
    color: $color-additional;
    line-height: 1.6667;
  }
}

h4 {
  font-size: 1.8rem;
  line-height: 1.6667;

  &.subhead {
    font-size: 1.4rem;
    color: $color-additional;
    line-height: 1.5;
  }
}

h5 {
  font-size: 1.6rem;
  line-height: 1.125;

  &.subhead {
    font-size: 1.4rem;
    color: $color-additional;
    line-height: 1.125;
  }
}

h6 {
  font-size: 1.4rem;
  line-height: 1.125;

  &.subhead {
    font-size: 1rem;
    color: $color-additional;
    line-height: 1;
  }
}

.bold-text {
  font-weight: 700;
}

.typography--inline {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-top: auto;
    margin-right: 15px;
  }
}

.typography-card {

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
  }
}

blockquote {
  font-size: 1.6rem;
  font-style: italic;
  border-left: 3px solid $color-accent;
  margin-bottom: 0;
  padding-left: 10px;
}

.highlight {
  background-color: $color-accent;
  color: #ffffff;
}

.red-text {
  color: #ad4444;
}

.page-title {
  position: relative;
  font-weight: 500;
  font-size: 2.4rem;
  padding: 0;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  &:last-child {
    margin-bottom: -5px;
    margin-left: 8px;
  }

  &.page-title--not-last {
    margin-top: 20px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.no-iframe-title {
    margin-bottom:15px;
  }

  &.dashboard-title {
    padding: 0;
    margin: 0 0 -0.5em;
  }
  &.dashboard-subtitle {
    padding: 0;
    margin: 0;
  }
}

.dashboard-subtitle {

  @media screen and (min-width: 768px) {
    width: calc(100% - 160px);
  }
}

.page-subhead {
  margin-bottom: 20px;

  &.subhead {
    font-size: 1.6rem;
    opacity: 0.7;
  }
}

::selection {
  color: #ffffff;
  background: $color-accent;
}

.link-primary {
  color: $color-orange;
}

.link-secondary {
  color: $color-blue;
}

.link-inherit {
  color: inherit;
}

button, input, optgroup, select, textarea {
  font-family: 'Open Sans', sans-serif;
}

.page-copy {

  h3, h4 {
    margin: 1.5rem 0 0.25rem;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  .card-body > h3:first-child {
    margin-top: 0;
  }

  p {
    margin: 0 0 1.5rem;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  ol, ul {
    margin: 0 0 1.5rem 1.5rem;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  li {
    margin: 0 0 0.5rem;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

}
