.card {
  width: 100%;
  padding-bottom: 0;
  height: auto;
  border: none;
  background-color: transparent;
  

  &.card--not-full-height {
    height: auto;
  }

  &.top-margin {
    margin-top: 30px;
  }
}

.card-body {
  position: relative;
  padding: 30px;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  &.card-sm {
    padding: 15px 20px;
  }
  &.card-nopad {
    padding: 0;
    overflow: hidden;
  }

  &.tall-table:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height:60px;
      background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 100%);
      pointer-events: none;
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &.no-margin {
    margin-bottom:0;

    + table {
      margin-top:1rem;
    }
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.page-filters {
  display: none;
}

.filter-cards {
  & > .card:first-child {
    min-height: 90px
  }
}


.success-icon {
  color: $color-accent;
  margin-top: -1px;
  margin-right: 8px;
} 


.iframe-video-wrapper {
  position: relative;
  max-width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 2rem 0;

  & iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .video-poster {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.featured-video-container {
  position: relative;
  background: #000;
  text-align: center;
  z-index: 1;
  margin: 2rem 0;

  &:after {
    position:absolute;
    display: block;
    content: '';
    top: 0;
    bottom: 0;
    left: -50vw;
    width: 150vw;
    background-color: #000;
    z-index: -1;
  }
}

.featured-video-wrapper {
  max-width:960px;
  margin:0 auto;

  & .iframe-video-wrapper {
    margin: 0 auto;
  }
}

.featured-video-controls {
  color:#fff;
  font-size: 2.4rem;

  .video-control-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    transition: 0.2s;
    border-radius: 100%;
    
    &:hover, &:focus {
      background-color:rgba(255,255,255,0.2);

    }
  }

  .video-prev-btn {
    left: 5%;

    & .lnr-chevron-left {
      margin-right: 3px;
      margin-top: -2px;
    }
  }

  .video-next-btn {
    right: 5%;
    & .lnr-chevron-right {
      margin-left: 3px;
      margin-top: -2px;
    }
  }

  @media screen and (min-width: 992px) {
    font-size: 2.8rem;

    .video-prev-btn .lnr-chevron-left {
        margin-top: -3px;
    }
  
    .video-next-btn .lnr-chevron-right {
        margin-top: -3px;
    }
  }
}

.iframe-container .iframe__release-notes,
.iframe-container .iframe__glossary {
  margin: 1.5em 0;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.video-poster-wrapper {
  position: relative;
  margin-top: 3rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  // box-shadow: 0 0 0 6px #f2f4f7, 0 1px 4px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.85;
  }

  &:focus {
    opacity: 0.85;
    outline: none;
    //box-shadow: 0 0 3px 3px #e35205;
    box-shadow: 0 0 3px 3px #e45525;
  }

  &.featured {
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 0 0 6px #f2f4f7, 0 0 2px 8px #e45525;
    // box-shadow: 0 0 0 6px #f2f4f7, 0 1px 4px 8px #e35205;
  }

  .video-play-icon {
    position: absolute;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0,0,0,0.3);
    font-weight: 600;
    height: 36px;
    width: 36px;
    bottom: 10px;
    left: 15px;
    z-index: 1;

    svg {
      height:36px;
      width:36px;
    }
  }

  .video-runtime {
    position: absolute;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0,0,0,0.3);
    font-weight: 600;
    font-size: 1em;
    bottom: 10px;
    right: 15px;
    z-index: 1;
  }

  @media (min-width: 768px) {

    .video-runtime {
      font-size: 1em;
    }
    
  }

  @media (min-width: 1200px) {
    .video-runtime {
      font-size: 1.2em;
    }
  }


  

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(0,0,0,0.1);
  // }
}