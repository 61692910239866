.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
  &.dashboard {

    & > .row {
      @include themify($themes) {
        background: themed("colorBackgroundBody");
      }
    }
  }
}

.container__wrap {
  position: relative;
  padding-left: 0;
  padding-top: 20px;
  overflow: hidden;
  height: calc(100vh - 60px);
  transition: padding-left 0.3s;
  // background: url(../../shared/img/bg/background_opacity30.jpg) no-repeat center
  //   center fixed !important;
  // background-size: 100% 100% !important;

  &.allow-overflow {
    overflow: auto;
    padding-bottom: 30px;
  }

  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  @media screen and (min-width: 768px) {
    padding-left: 220px;
    padding-right: 0;
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 768px) {
        padding-left: 55px;
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 768px) {
        padding-left: 0;
      }
    }
  }
}

.iframe-container {
  overflow: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;

  & iframe {
    position: relative;
    z-index: 0;
    border: none;
    margin: 0;
    padding: 0;
  }

  & .iframe-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    & iframe {
      //min-height: calc(100vh - 150px);
      flex-grow: 1; 
    }
  }
}

.non-iframe-container {
  padding-left: 8px;
  padding-right: 8px;

  &.overflow {
    margin-top:1rem;
    padding-bottom: 10rem;
    overflow-y: auto;
  }

  &.page-copy {
    padding-right: 2rem;
  }
}

.dashboard__home {
  margin-top: -40px;

  & iframe {
    /*min-height:calc(100vh - 90px);*/
  }
}

.row.row__dashboard-intro {
  margin-bottom: -5px;
  padding: 0 0 10px;
}

.dashboard-intro {
  position: relative;
  z-index: 1;
  padding: 0 23px 0 23px;
  margin: 0;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  } 
}

.page-info-section {
  position: absolute;
  display: flex;
  align-items: center;
  top: 20px;
  right: 15px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    top: 30px;
  }
}
.container__wrap.files-page {
  padding-top:0;
}
.files-container {
  display: flex;
  width: 100%;

  & #exavault-share-widget {
    position: relative;
    z-index: 1;
  }
}

.flex-container {
  display: flex;

  &.flex-row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  &.flex-col {
    flex-direction: column;
  }
}

.overflow-scroll {
  overflow: auto;
}
