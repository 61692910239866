.home-chart-card {
    width: 100%;
    background: #fff;
    padding:2rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px #ddd;
}
.team-card-container {
        background: #fff;
        padding:2rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px #ddd;
}
.team-card + .team-card {
    margin-top: 2em;
}
.home-chart-card header {
    font-size:1.2rem; margin: 0 0 1rem;
}
.home-chart-card header .header-value {
    font-size:2rem; margin-right: 3px;
}

.charts-css.column {
    height: 100px;
    max-width: 100%;
    margin: 0 auto;
}

#web-visits-table.charts-css.column {
    --color: #007299;
}

#form-submissions-table.charts-css.column {
    --color: #b86b00;
}

#leads-table tbody tr:first-child td:last-child {
    background-color: #FD9A93;
}
#leads-table tbody tr:nth-child(2) td:last-child {
    background-color: #FBAD56;
}
#leads-table tbody tr:nth-child(3) td:last-child {
    background-color:#90C4E4;
}
.charts-css.column.data-spacing-1 tr td:last-child {
    width: 100%;
}
.charts-css.column.data-spacing-1 tbody tr {
    padding-inline-start: 0;
    max-width: 1%;
}

.team-card {
    font-size: 0.875em;
}

.team-card + .team-card {
    margin-top: 2em;
}
@media screen and (min-width: 1024px) {
    .home-chart-card {
        flex: 0 0 33%;
        max-width: 400px;
    }
    #web-visits-table.charts-css.column,
    #form-submissions-table.charts-css.column {
        height: 80px;
    }
}