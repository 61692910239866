.modal-open .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.modal-dialog {
    font-size: 1.33rem;
    max-width: 80vw;
    

    & .modal-content {
        padding: 1.5rem 2rem 2rem;
    }

    & .modal-header {
        position: relative;
        font-size: 1.33rem;
        font-weight: 600;
        border: none;
        padding: 0 0 1rem;
        margin: 0;

        & .close {
            font-size:2.4rem;
            line-height: 0.75em;
            margin: -1.2rem -1.6rem -1rem auto;

            &:focus {
                outline: auto;
            }
        }

        & .sic-survey-close {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    & .modal-body {
        padding: 0; 
        margin: 0;

        & ul {
            padding-left: 1.8rem;
        }

        & strong {
            font-weight: 600;
        }
    }

    & a:not(.btn) {
        text-decoration: underline;
    }
    
    @media (min-width:768px) {
        max-width: 50vw;
    }

    @media (min-width:1200px) {
        max-width: 600px;
    }
}

.leads-whats-new-modal {
    font-size: clamp(1.33rem, 2vw, 1.5rem);
    line-height: 1.67;

    & .modal-title {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.25;
    }

    & li {
        margin-top: 0.5rem;
    }
}

.sic-survey-modal {
    font-size: clamp(1.4rem, 2.25vw, 1.6rem);
    line-height: 1.67;
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    max-width: 530px;

    & .modal-header {
        justify-content: center;
        padding: 2vw 2vw 1em 2vw;
    }

    & .modal-title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.25;
    }

    & .modal-body {
        padding: 1em 2vw 2vw 2vw;
    }

    & .btn {
        margin: 1em auto;
    }
}


.modal-dialog.forms-modal {
    @media (min-width:768px) {
        max-width: 80vw;
    }

    @media (min-width:1200px) {
        max-width: 80vw;
    }
}

.hover-tooltip__inner {
    padding: 2px 8px;
    font-size: 1.2rem;
    background-color: #222427;
}

.hover-tooltip__arrow::before {
    border-top-color: #222427 !important;
}

.modal-filter-backdrop {
	opacity: 0;
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(255,255,255,0.1);

    & .show {
        opacity: 1;
        z-index: 1;
    }
}