/* dropdown buttons */ 
.dropdown-toggle {
    border-radius: 0;

    .filter-selection__wrapper {
        display:inline-block;
        min-width: 130px;
        padding:0 5px;
        text-align: left;

        .filter-selection {
            display:inline-block;
            padding:2px 8px;
            border-radius:5px;
            background-color:#e2e4e7;
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: inherit;
    background-color:#ffffff;
    border-color:#ffffff;
    outline:none;
}

.dropdown-menu {
    min-width:100%;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.15);

    .dropdown-item {
        font-size:1.4rem;
        line-height: 1.25;
        padding: 0.75em 1em;

        &:hover, &:focus, &:active {
            background-color:darken(#ffffff, 10%)
        }
    }

    
}
